.prevButton {
    border-width: 0;
    left: 20px;
    background-color: transparent;
}

.nextButton {
    border-width: 0;
    right: 20px;
    background-color: transparent;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}

@media (max-width: 1024) {
.buttonContainer{
    
}
    
    /* Mobile */
    .prevButton {
        border-width: 0;
        z-index: 200;
        position: fixed;
        margin-top: -50px;
        left: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: transparent;
    }

    .nextButton {
        border-width: 0;
        z-index: 200;
        position: fixed;
        margin-top: -50px;
        right: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: transparent;
    }
}

.checkbox-label {
    display: inline-block;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .checkbox-label.selected {
    background-color: #d4edda; /* Light green background */
    color: #155724; /* Dark green text */
  }
  