.prevButton {
    border-width: 0;
    left: 20px;
    background-color: transparent;
}

.nextButton {
    border-width: 0;
    right: 20px;
    background-color: transparent;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   
    width:100%;
    margin-bottom: 0.1rem;
    


}

@media (max-width: 1024) {

    
    /* Mobile */
    .prevButton {
        border-width: 0;
        z-index: 200;
        position: fixed;
 
        left: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: transparent;
    }

    .nextButton {
        border-width: 0;
        z-index: 200;
        position: fixed;

        right: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: transparent;
    }
}