.container-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    align-items: center;
    justify-content: center;
    position: relative;
  }

.container p {
    color: red;
    text-align: center;
}

.drop-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 800px;
    height: 200px;
    border: 4px dashed #4aa1f3;
}
.upload-icon {
    width: 50px;
    height: 50px;
    background: url(./file.png) no-repeat center center; 
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 30px;
}

.drop-message {
    text-align: center;
    color: #4aa1f3;
    font-family: Arial;
    font-size: 20px;
}
.file-display-container {
    /* position: fixed; */
    width: 805px;
}

.file-status-bar{
    width: 100%;
    vertical-align:top;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    line-height: 50px;
    height: 50px;
}

.file-status-bar > div {
    overflow: hidden;
}

.file-type {
    display: inline-block!important;
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    margin-top: 25px;
    padding: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #abc;
    color: #fff;
    background: #0080c8;
    text-transform: uppercase;
}

.file-name {
    display: inline-block;
    vertical-align:top;
    margin-left: 50px;
    color: #4aa1f3;
}

.file-error {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    color: #9aa9bb;
}

.file-error-message {
    color: red;
}

.file-type-logo {
    width: 80px;
    height: 80px;
    background: no-repeat center center; 
    background-size: 100%;
    position: absolute;
}

.file-size {
    display:inline-block;
    vertical-align:top;
    color:#30693D;
    margin-left:10px;
    margin-right:5px;
    margin-left: 10px;
    color: #444242;
    font-weight: 700;
    font-size: 14px;
}

.file-remove  {
    position: absolute;
    top: 20px;
    right: 10px;
    line-height: 15px;
    cursor: pointer;
    color: red;
    margin-right: -10px;
}
.modal {
    z-index: 999;
    display: none;
    overflow: hidden;
    position: fixed; /* Add this line */
    top: 0; /* Add this line to position it at the top */
    left: 0; /* Add this line to position it at the left */
    width: 100%; /* Add this line to make it full-width */
    height: 100%; /* Add this line to make it full-height */
}

.modal .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .66);
    position: absolute;
    top: 0;
    left: 0;
}

.modal .modal-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}
.upload-btn-container {
   margin-top: 10px;
   
    top: 0;
    left: 0;
    
    
}
.file-upload-btn {
    color: white;
    text-transform: uppercase;
    outline: none;
    background-color: #4aa1f3;
    font-weight: bold;
    padding: 8px 15px;
    margin-bottom: 5px;
}
.file-input {
    display: none;
}
.upload-modal {
    z-index: 999;
    display: none;
    overflow: hidden;
}

.upload-modal .overlay{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.66);
    position: absolute;
    top: 0;
    left: 0;
}

/* .progress-container {
    background: white;
    width: 500px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    overflow: hidden;
} */

.progress-container span {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    font-size: 20px;
}

/* .progress {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #efefef;
    height: 20px;
    border-radius: 5px;
} */

.progress-bar {
    position: absolute;
    background-color: #4aa1f3;
    height: 20px;
    border-radius: 5px;
    text-align: center;
    color: greenyellow;
    font-weight: bold;
}

.error {
    color: red;
}

.progress-container {
    width: 50%;
    background-color: #f1f1f1;
    height: 200px;
    margin-top: 10px;
    overflow: hidden;
    border-radius: 5px;
    background: white;
    width: 500px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    overflow: hidden;
  }
  
  .progress {
    background-color: #4caf50;
    width: 0;
    height: 100%;
    transition: width 0.3s;
  }
  .progress-container-File {
    width: 100%;
    height: 20px;
    background-color: #f1f1f1;
    border-radius: 5px;
    margin-top: 10px;
    overflow: hidden;
  }
  
  .progress-bar-File {
    height: 100%;
    background-color: #4aa1f3;
    transition: width 0.3s;
  }
  
  