.tooltip-container {
  position: relative;
  display: inline-block;
}
.tooltip-text {
  visibility: hidden;
  max-width: 14rem;
  width: 70vw;
  background: #fff2f0;
  color: #ee5942;
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  box-shadow: 0px 1px 10px 0px #3757e226;
  border: 1px 0px 0px 0px;
  border: 1px solid #fff2f0;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: 70%;
  left: 100%;
  opacity: 0;
  gap: 10px;
}
.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.infoIcon {
  cursor: pointer;
}

/*web*/
.tooltip-text {
  top: 70%;
  left: 100%;
  border-radius: 0px 16px 16px 16px;
}

/* Mobile*/
@media (max-width: 768px) {
  .tooltip-text {
    left: auto;
    right: 100%;
    border-radius: 16px 0px 16px 16px;
  }
}

/* Tablet*/
@media (min-width: 769px) and (max-width: 1024px) {
  .tooltip-text {
    left: auto;
    right: 100%;
    border-radius: 16px 0px 16px 16px;
  }
}