table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    border: 1px solid black;
    text-align: left;
    padding: 8px;
  }
  
  th {
    background-color: #ddd;
  }
.container {
    width: 100%;
  overflow-x: auto;
}
.cache {
  position: fixed;
    width: 14px;
    height: 14px;
    bottom: -40px;
    right: 60px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 30px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}