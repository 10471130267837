/* body {
    text-align: center;
  } */
.dropbox {
  text-align: center;
  padding: 20px;
  width: 90%;
  margin: auto;
  margin-top: 50px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 20px auto;
  padding: 20px;
}

.container img {
  height: 200px;
  width: 200px;
  margin-right: 15px;
}

.btn {
  padding: 15px;
  background-color: #de1a1a;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.btn:hover {
  background-color: #945c5c;
}