.questionContainer{

    display: flex;
    direction: "column";
    flex-wrap: wrap;
    gap:20%;
    justify-content: space-around;
    border: 1px solid black;
    padding: 1%;
    
    
    }
    .questionImage{
        /* overflow: auto; */
        /* width: auto; */
        /* object-fit: cover; */
     
         max-width: 60%;
         overflow: auto;
         /* min-height: calc(100svh/2); */
         /* max-height: calc(100dvh);
        max-height: calc(100svh );  */
      
    
    }
    .topicsContainer {
      overflow: auto;
      /* width: 40%; */
      max-width: calc(100vh / 2); /* Use 'vh' for viewport height */
      /* max-width: calc(100svh / 2); */ /* Remove this line, 'svh' is not a valid unit */
      /* max-height: calc(100sdvh); */ /* Remove this line, 'sdvh' is not a valid unit */
      max-height: calc(100vh); /* Use 'vh' for viewport height */
    }
    
    @media (max-width: 1024px) { /* Tablet */
        .questionContainer {
            display: flex;
            gap: 5rem;
            flex-direction: "row"; /* Change direction to 'row' for tablet */
           
        }
        .questionImage {
          max-width: 100%;
          max-height: calc(100vh / 2); /* Use 'vh' for viewport height */
          /* max-height: calc(100svh / 2); */ /* Remove this line, 'svh' is not a valid unit */
          overflow: auto;
      }
      .topicsContainer {
        width: 100vh; /* This sets the width of the container to 100% of the viewport height */
        max-width: 100%; /* This ensures that the container does not exceed the width of its parent */
        max-height: calc(100vh / 2); /* This sets the maximum height of the container to half of the viewport height */
        /* max-height: calc(100svh / 2); */ /* Remove this line, 'svh' is not a valid unit */
        overflow-y: auto; /* This enables vertical scrolling when the content overflows the container */
    }
    }
    
    @media (max-width: 480px) { /* Mobile */
        .questionContainer {
            display: flex;
            gap: 1rem; /* Adjust gap for smaller screens */
            flex-direction: column; /* Change direction to 'column' for mobile */
            /* Example background color */
        }
        .questionImage, .topicsContainer {
          max-width: 100%;
          max-height: 100%; /* Adjust maximum height for mobile, using 'vh' for viewport height */
          /* max-height: calc(100svh / 2); */ /* Remove this line, 'svh' is not a valid unit */
          overflow-y: auto; /* Enable vertical scrolling if needed */
      }
    }
    
    /* Modal Styles */
    .modal {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
      
      .modal-content {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        height: auto;
      }
      
      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
      
      /* Responsive Styles */
      @media (max-width: 768px) {
        .modal-content {
          width: 80%;
        }
      }
      
      @media (max-width: 480px) { 
        .modal-content {
          width: 90%;
        }
      }
      
    