/* SwitchComponent.css */

.switch-container {
    display: flex;
  }
  
  .switch-button {
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .switch-button.active {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
  }
  