.overlay {
  position: fixed;
  background: #121212;
  opacity: 35%;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.modal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background-color: #ffffff;
  /* width: 460px;
  height: 530px; */
  gap: 24px;
  padding: 20px 40px 20px 40px;
  border-radius: 12px;
  border: 1px;
  z-index: 1;
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y:hidden;
}
