.table-container {
    overflow-x: auto;
  }
  
  .responsive-table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
  }
  
  .responsive-table th,
  .responsive-table td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: center; /* Center align the text in cells */
  }
  
  /* Set the width for each column */
  .responsive-table th:nth-child(1),
  .responsive-table td:nth-child(1) {
    width: 30%;
  }
  
  .responsive-table th:nth-child(2),
  .responsive-table td:nth-child(2) {
    width: 30%;
  }
  
  .responsive-table th:nth-child(3),
  .responsive-table td:nth-child(3) {
    width: 40%;
  }
  
  /* Add additional CSS styling as per your requirements */
  